<template>
    <header class="header">
        <div class="header-content">
            <div class="logo">
                <img src="@/assets/logo.png" alt="Speedy Img" class="logo-image">
            </div>
            <div class="nav-items">
                <button v-if="!isLoggedIn" @click="showPlans = true" class="pricing-btn">Pricing</button>
                <button v-if="!isLoggedIn" @click="showLogin = true" class="login-btn">Log In</button>
                <button v-if="!isLoggedIn" @click="showSignup = true" class="signup-btn">Sign Up</button>
                <button v-if="isLoggedIn" @click="showSettings = true" class="login-btn">Settings</button>
                <button v-if="isLoggedIn && !hasPremium" @click="showPlans = true" class="premium-btn">Get Premium</button>
            </div>
        </div>
    </header>
    <div class="model signup" v-if="showSignup">
        <div class="model-container">
            <div>
                <h2>Sign Up</h2>
                <button @click="showSignup = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div>
                <input v-model="email" type="email" placeholder="Email">
                <div class="password-container">
                    <input v-model="password.password" type="password" placeholder="Password">
                    <i @click="showPasswordInfo = !showPasswordInfo" class="icon icon-info"></i> 
                    <div v-if="showPasswordInfo" class="password-info">
                        <p>Password Requirements:</p>
                        <div>
                            <ul>
                                <li>Minimum length of 6 characters</li>
                                <li>At least one lowercase letter</li>
                                <li>At least one uppercase letter</li>
                                <li>At least one number</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <button @click="signup" class="submit-btn">Sign Up</button>
                <div class="other">
                    Or Log In Using: 
                    <button @click="googleAuth" class="googe-btn"><i class="icon icon-google"></i> Continue with Google</button>
                </div>
                <div v-if="error" class="error-text">
                    <p>{{ error }}</p>
                </div>
                <div class="footer">
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
                </div>
            </div> 
        </div>
    </div>

    <div class="model login" v-if="showLogin">
        <div class="model-container">
            <div>
                <h2>Log In</h2>
                <button @click="showLogin = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div>
                <input v-model="email" type="email" placeholder="Email">
                <input v-model="password.password" type="password" placeholder="Password">
                <button @click="login" class="submit-btn">Log In</button>
                <div class="other">
                    Or Log In Using: 
                    <button @click="googleAuth" class="googe-btn"><i class="icon icon-google"></i> Continue with Google</button>
                </div>
                <div class="reset-password">
                    <button @click="resetLoggedOutPasswordRequest">Forgot Password? Click to reset.</button>
                </div>
                <div v-if="error" class="error-text">
                    <p>{{ error }}</p>
                </div>
            </div> 
        </div>
    </div>

    <div class="model password-reset" v-if="showPasswordReset">
        <div class="model-container">
            <div>
                <h2>Reset Password</h2>
                <button @click="showPasswordReset = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div>
                <div class="password-container">
                    <input v-model="password.password" type="password" placeholder="Password">
                    <i @click="showPasswordInfo = !showPasswordInfo" class="icon icon-info"></i> 
                    <div v-if="showPasswordInfo" class="password-info">
                        <p>Password Requirements:</p>
                        <div>
                            <ul>
                                <li>Minimum length of 6 characters</li>
                                <li>At least one lowercase letter</li>
                                <li>At least one uppercase letter</li>
                                <li>At least one number</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <input v-model="password.confirm" type="password" placeholder="Confirm Password">
                <button @click="resetPassword" class="submit-btn">Reset Password</button>
                <div v-if="error" class="error-text">
                    <p>{{ error }}</p>
                </div>
            </div> 
        </div>
    </div>

    <div class="model plans" v-if="showPlans">
        <div class="model-container">
            <div>
                <h2>Plans</h2>
                <button @click="showPlans = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div class="plans_wrapper">
                <div class="plan">
                    <h3>Monthly</h3>
                    <h4>${{ plans['51CE5A4D-7807-42E1-B2CD-562FC9A9926E'] }} USD / Month</h4>
                    <p class="feature"><i class="icon icon-check"></i> Unlimited Uploads</p>
                    <p class="feature"><i class="icon icon-check"></i> File Conversion</p>
                    <p class="feature"><i class="icon icon-check"></i> Bulk Downloads</p>
                    <button v-if="isLoggedIn" @click="selectPlan('51CE5A4D-7807-42E1-B2CD-562FC9A9926E')" class="setting-btn">Select Montly</button>
                </div>
                <div class="plan">
                    <h3>Yearly</h3>
                    <h4>${{ plans['af51adaa-5894-4bf4-8429-e52b93bb0f3f'] }} USD / Year</h4>
                    <p class="feature"><i class="icon icon-check"></i> Unlimited Uploads</p>
                    <p class="feature"><i class="icon icon-check"></i> File Conversion</p>
                    <p class="feature"><i class="icon icon-check"></i> Bulk Downloads</p>
                    <button v-if="isLoggedIn" @click="selectPlan('af51adaa-5894-4bf4-8429-e52b93bb0f3f')" class="setting-btn">Select Yearly</button>
                </div>
            </div>
        </div>
    </div>

    <div class="model payment" v-if="showPayment">
        <div class="model-container">
            <div>
                <h2>Payment</h2>
                <button @click="showPayment = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div>
                <div class="form">
                    <div>
                        <label>Card Number</label>
                        <span class="input"><div id="card-number-element"></div></span>
                    </div>
                    <div class="double">
                        <div>
                            <label>Expiration Date</label>
                            <span class="input"><div id="card-expiry-element"></div></span>
                        </div>
                        <div>
                            <label>CVC</label>
                            <span class="input"><div id="card-cvc-element"></div></span>
                        </div>
                    </div>
                    <button @click="payWithStripe" v-bind:class="{ 'is-disabled' : submitting, error : stripe.failed }">{{ paymentButtonText }}</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="model settings" v-if="showSettings">
        <div class="model-container">
            <div>
                <h2>Settings</h2>
                <button @click="showSettings = false" class="model-close"><i class="icon icon-close"></i></button>
            </div>
            <div>
                <div class="setting">
                    <p>Log Out</p>
                    <button @click="logout" class="setting-btn">Log Out</button>
                </div>
                <div class="setting">
                    <p>Reset Password</p>
                    <button @click="resetPasswordRequest" class="setting-btn">Reset</button>
                </div>
                <div class="setting">
                    <p>Delete Account</p>
                    <button @click="deleteAccount" class="setting-btn">Delete</button>
                </div>
                <div v-if="hasPremium" class="setting">
                    <p>Cancel Subscription</p>
                    <button @click="cancelSubscription" class="setting-btn">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

// const LOGIN = 'Log In';
// const SIGNUP = 'Sign Up';
const INVALID_EMAIL = 'Invalid Email Address';
const INVALID_PASSWORD = 'Invalid Password';
// const PASSWORD_REQUIREMENTS = 'Invalid Password';
const MISSMATCH_PASSWORD = 'Passwords Don\'t Match';
const INVALID_LOGIN = 'Invalid Email Or Password';
// const ENTER_EMAIL = 'Enter Your Email';
const PROCESS = 'Process Payment';
const PROCESSING = 'Processing';
const FAILED = 'Payment Failed';
const ENTER_EMAIL = 'Enter Your Email Above To Reset';
// const RESET = 'Reset Password';

if (window.utils.hash('access_token')) {
    window.services.google({
        'token': window.utils.hash('access_token')
    })
    .then(function () {
        window.location.href = window.location.origin;
    })
    .catch(function () {
        Swal.fire({
            title: 'Login Failed',
            text: "Sorry, something went wrong while trying to login to your google account.",
            type: 'warning',
        }).then(() => {
            window.location.href = window.location.origin;
        });
    });
}

export default {
    name: 'TopHeader',
    data() {
        return {
            services: window.services,
            utils: window.utils,
            Stripe: window.Stripe,
            showSignup: false,
            showLogin: false,
            showSettings: false,
            showPlans: false,
            showPayment: false,
            showPasswordReset: false,
            // isLoggedIn: true,
            isLoggedIn: window.utils.cookies.has('token'),
            hasPremium: window.utils.cookies.has('3hiagdlh5disdsag43234gh') || window.utils.cookies.has('hidl4ah4jaad3gadsdfa5dih'),
            showPasswordInfo: false,
            email: '',
            password: {
                token: null,
                password: '',
                confirm: '',
            },
            error: null,
            cookiePolling: null,
            plan: null,
            submitting: false,
            paymentButtonText: PROCESS,
            stripe: {
                stripe: null,
                publicKey: 'pk_live_360ATOn250DwPCxjxIVwHEFT',
                elements: null,
                cardNumberElement: null,
                cardExpiryElement: null,
                cardCvcElement: null,
                succeeded: false,
                failed: false,
            },
            plans: {
                '51CE5A4D-7807-42E1-B2CD-562FC9A9926E': '2',
                'af51adaa-5894-4bf4-8429-e52b93bb0f3f': '20'
            }
        };
    },
    created() {
        const c = this;

        let resetToken = c.utils.url.param({url: window.location.href, name: 'reset-token'});
        if (resetToken) {
            c.showPasswordReset = true;
            c.password.token = resetToken;
        }

        // c.cookiePolling = setInterval(() => {
        //   c.isLoggedIn = c.utils.cookies.has('token');

        //   if (c.isLoggedIn) {
        //     c.showSignup = false;
        //     c.showLogin = false;
        //   }	
        // }, 1000);
    },
    // beforeUnmounted() {
    //   clearInterval(this.cookiePolling);
    // },
    // watch: {
    //     'loggedIn'(newVal, oldVal) {
    //         if (newVal !== oldVal) {
    //             clearInterval(this.cookiePolling);
    //         }
    //     },
    // },
    methods: {
        login() {
            const c = this;

            c.error = null;

            c.services.login({
                email: c.email, 
                password: c.password.password
            })
            .then(function () {
                c.showLogin = false;
                c.isLoggedIn = true;

                window.checkPremium();

                setTimeout(function() {
                    window.location.reload();
                }, 300);
            })
            .catch(function () {
                c.error = INVALID_LOGIN;
            });
        },
        signup() {
            const c = this;

            if (!c.utils.validateEmail(c.email)) {
                c.error = INVALID_EMAIL;

                return;
            }

            if (!c.utils.validatePassword(c.password.password)) {
                c.error = INVALID_PASSWORD;

                c.showPasswordInfo = true;

                return;
            }

            c.error = null;

            c.services.signup({
                email: c.email, 
                password: c.password.password
            })
            .then(function () {
                c.showSignup = false;
                c.isLoggedIn = true;
            })
            .catch(function (error) {

                let response = error.response.data;

                if (response.error) {
                    c.error = error.response.data.error;
                } else if (response.key === 'email') {
                    c.error = INVALID_EMAIL;
                } else if (response.key === 'password') {
                    c.error = INVALID_PASSWORD;
                }
                
            });
        },
        googleAuth() {
            let clientId = '387766688869-dgidhqo3ehh0km8hi16f7qulmj6knc42.apps.googleusercontent.com';
            let redirectUrl = 'https://speedyimg.com';

            let scopes = [
                'openid',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/userinfo.email',
              ]
            
            scopes = scopes.join(' ')

            window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=${scopes}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${clientId}`;
        },
        cancelSubscription() {
            const c = this;

            Swal.fire({
                title: 'Cancel Subscription?',
                text: "Are you sure you want to cancel your subscription?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Process'
            }).then((result) => {
                if (result.value) {

                    c.services.cancelSubscription()
                    .then(function () {

                        c.hasPremium = false;

                        Swal.fire({
                            title: 'Subscription Canceled',
                            text: "We have canceled your subscription.",
                            icon: 'info',
                        });

                    })
                    .catch(function () {

                        Swal.fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            icon: 'error',
                        });

                    });

                }
            });
        },
        resetLoggedOutPasswordRequest() {
            const c = this;

            if (!c.email) {
                c.error = ENTER_EMAIL;
                return;
            }

            Swal.fire({
                title: 'Reset Password?',
                text: "We will send you an email with a link to reset your password.",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Reset'
            }).then((result) => {
                if (result.value) {

                    c.services.resetPassword({
                       email: c.email,
                    })
                    .then(function () {

                        Swal.fire({
                            title: 'Reset Password',
                            text: "We have sent an email to your inbox with a reset passoword link.",
                            icon: 'info',
                        });

                    })
                    .catch(function () {

                        Swal.fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            icon: 'error',
                        });

                    });

                }
            });
        },
        resetPasswordRequest() {
            const c = this;

            // if (!c.email) {
            //     c.buttons.login.text = ENTER_EMAIL;
            //     c.buttons.login.error = true;
            // }

            Swal.fire({
                title: 'Reset Password?',
                text: "We will send you an email with a link to reset your password.",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Reset'
            }).then((result) => {
                if (result.value) {

                    c.services.resetPassword({
                       
                    })
                    .then(function () {

                        Swal.fire({
                            title: 'Reset Password',
                            text: "We have sent an email to your inbox with a reset passoword link.",
                            icon: 'info',
                        });

                    })
                    .catch(function () {

                        Swal.fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            icon: 'error',
                        });

                    });

                }
            });
        },
        resetPassword() {
            const c = this;

            if (!c.password.token) {
                return;
            }

            c.error = null;

            if (!c.utils.validatePassword(c.password.password)) {
                c.error = INVALID_PASSWORD;

                return;
            }

            if (c.password.confirm !== c.password.confirm) {
                c.error = MISSMATCH_PASSWORD;

                return;
            }

            c.services.resetPassword({
                token: c.password.token, 
                password: c.password.password,
            })
            .then(function (data) {
                
                if (data.data.error) {
                    c.error = data.data.error;
                } else {

                    Swal.fire({
                        title: 'Password Reset',
                        text: "Password was successfully reset.",
                    }).then(() => {
                        setTimeout(function() {
                            window.location.replace(window.location.pathname);
                            window.location.reload();
                        }, 1000);
                    });

                }

            })
            .catch(function () {
                c.error = MISSMATCH_PASSWORD;
            });
        },
        deleteAccount() {
            const c = this;

            Swal.fire({
                title: 'Delete Account',
                text: "Are you sure you want to delete your account? This can't be undone.",
                type: 'warning',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {

                    c.services.delete()
                    .then(function () {
                        c.logout();
                    })
                    .catch(function () {

                        Swal.fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            type: 'error',
                        });

                    });
                }
            });
        },
        logout() {
            const c = this;

            c.utils.cookies.delete('token');
            c.utils.cookies.delete('3hiagdlh5disdsag43234gh');
            c.utils.cookies.delete('hidl4ah4jaad3gadsdfa5dih');

            window.location.href = `${window.location.origin}/api/user/logout`;
        },
        selectPlan(planID) {
            const c = this;

            c.plan = planID;

            c.showPlans = false;
            c.showPayment = true;

            c.paymentButtonText = `${PROCESS} - $${c.plans[planID]}`;
            
            setTimeout(function() {
                c.initStripe();
            }, 200);
        },
        initStripe() {
            const c = this;

            // Initialize Stripe with your public key
            c.stripe.stripe = c.Stripe(c.stripe.publicKey);

            const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };

            // Initialize elements
            c.stripe.elements = c.stripe.stripe.elements();

            // Create an instance of the card number Element
            c.stripe.cardNumberElement = c.stripe.elements.create('cardNumber', {style: style});
            c.stripe.cardNumberElement.mount('#card-number-element');

            // Create an instance of the expiry Element
            c.stripe.cardExpiryElement = c.stripe.elements.create('cardExpiry', {style: style});
            c.stripe.cardExpiryElement.mount('#card-expiry-element');

            // Create an instance of the CVC Element
            c.stripe.cardCvcElement = c.stripe.elements.create('cardCvc', {style: style});
            c.stripe.cardCvcElement.mount('#card-cvc-element');
        },  
        async payWithStripe() {
            const c = this;

            c.submitting = true;
            c.paymentButtonText = PROCESSING;

            const { paymentMethod, error } = await c.stripe.stripe.createPaymentMethod({
                type: "card",
                card: c.stripe.cardNumberElement,
            });
        
            if (paymentMethod) {
                c.processPayment(paymentMethod);
            } else {
                console.error(error);

                c.showError();
            }
        },
        processPayment(token) {
            const c = this;

            c.services.submitPayment({ token: token.id, planID: c.plan })
            .then(function (response) {
                if (!response.data.error) {
                    c.validatePayment(response.data.id);
                } else {
                    c.showError(response.data.error);
                }
            })
            .catch(function (error) {
                console.error('Payment Error:', error);
            });
        },
        validatePayment(id) {
            const c = this;

            c.services.getPayments()
            .then(function (response) {

                for (let payment of response.data) {

                    if (payment.id == id) {

                        if (payment.succeeded) {

                            c.stripe.succeeded = true;
                            c.submitting = false;

                            setTimeout(function() {
                                c.hasPremium = window.utils.cookies.has('3hiagdlh5disdsag43234gh') || window.utils.cookies.has('hidl4ah4jaad3gadsdfa5dih')
                            }, 300);

                            Swal.fire({
                                title: 'Payment Complete',
                                text: "Thanks for purchasing premium access. New features and limits have been unlocked",
                                type: 'success',
                            }).then(() => {
                                window.location.reload();
                            });

                            c.close();

                        } else if (payment.failed) {
                            c.showError();
                        } else {
                            setTimeout(function() {
                                c.validatePayment();
                            }, 1000);
                        }

                    } else {
                        setTimeout(function() {
                            c.validatePayment();
                        }, 1000);
                    }

                }

            });
        },
        showError(message = null) {
            const c = this;

            c.stripe.failed = true;
            c.paymentButtonText = FAILED;

            if (message) {

                Swal.fire({
                    title: 'Sorry, payment failed.',
                    text: message,
                    type: 'error',
                });

            }

            c.removeError();
        },
        removeError() {
            const c = this;

            setTimeout(function() {

                if (c.stripe.failed) {

                    c.stripe.succeeded = false;
                    c.stripe.failed = false;
                    c.submitting = false;

                }

            }, 3000);
        },
        close() {
            const c = this;

            c.showSignup = false;
            c.showLogin = false;
            c.showSettings = false;
            c.showPlans = false;
            c.showPayment = false;
            c.showPasswordReset = false;
        }
    }
}
</script>
<style>

.swal2-container {
  font-family: Arial, Helvetica, sans-serif; 
}

.swal2-confirm {
    background: #3ca84b;
}
</style>

<style scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #ffffff;
    z-index: 100;
    zoom: .7;

    @media screen and (min-width: 768px) {
        zoom: 1;
    }
}

.header-content {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 50px;
}

.logo-image {
    height: 100%;
    width: auto;
}

.login-btn,
.pricing-btn {
    padding: 10px;
    background: transparent;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
    margin-left: 10px;
    color: #000;
    border: none;
}

.signup-btn {
    margin-left: 10px;
}

.premium-btn,
.signup-btn {
    padding: 10px 24px;
    background: #3ca84b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.pricing-btn:hover,
.login-btn:hover,
.signup-btn:hover,
.submit-btn:hover,
.setting-btn:hover,
.model-close:hover {
    opacity: 0.5;
}

.model {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border: none;
    border-radius: 0
}

.model-container {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 400px;
    position: relative;
    margin: 10px;
}

.model-close {
    position: absolute;
    top: 4px;
    right: 1px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.model input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    display: block;
    width: 100%; 
    box-sizing: border-box;
}

.model .submit-btn {
    padding: 10px 24px;
    background: #3ca84b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.model .other {
    text-align: center;
    margin: 20px 0;
}

.model .googe-btn {
    display: block;
    padding: 10px 24px;
    background: #37afd3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px; 
}

.model .googe-btn > i {
    position: relative;
    top: 2px;
    left: -5px;
    font: 10px;
}

.model .icon-info {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.model .icon-info:hover {
    opacity: .5;
}

.model h2 {
    margin-top: 0;
}

.password-container {
    position: relative;
}

.model .setting {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-bottom: 20px;
    text-align: left;
}

.model .setting:last-of-type {
    margin-bottom: 0;
}

.model .setting-btn {
    padding: 10px 24px;
    background: #3ca84b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
    border: 2px solid #3ca84b;
}

.password-info {
    position: absolute;	
    background: #fff;
    border-radius: 5px;
    right: 0;
    width: 300px;
    top: 230px;
    text-align: left;
    padding: 20px;

    @media screen and (min-width: 768px) {
        right: -368px;
        top: -38px;
    }
}

.password-info > p {
    font-weight: bold;
}

.password-info > div {
    padding-left: 20px;
}

.password-info > div > ul {
    list-style-type: disc;
    padding-left: 0; 
}

.error-text {
    color: #a11818;
    font-weight: bold;
}

.plans_wrapper {
    display: grid;
    gap: 20px;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    }
}

.plans_wrapper .plan {
    background-color: #f1f1f1;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
    padding-bottom: 10px;
    display: block;
}

.plans_wrapper .plan h3 {
    background-color:  #d6d6d6;
    margin-top: 0;
    padding: 10px;
}

.plans_wrapper .plan p {
    position: relative;
}

.plans_wrapper .plan i {
    color: #e7ab3b;
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 16px;
}

.is-disabled {
    opacity: .35;
    pointer-events: none;
}

.reset-password {
    border-top: 1px solid rgb(241, 241, 241); 
    margin-top: 10px;
}

.reset-password button {
    margin-top: 10px;
    background: transparent;
    border: none;
    color: #37afd3;
}

.reset-password button:hover {
    opacity: .5;
}

.model.payment .form label {
    text-align: left;
    display: block;
}

.model.payment .form .input {
    border: 1px solid #d7d6d6;
    border-radius: 3px;
    padding: 10px;
    display: block;
    margin: 10px 0 20px;
}

.model.payment .form .double {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.model.payment .form button {
    padding: 10px 24px;
    background: #3ca84b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
    border: 2px solid #3ca84b;
    width: 100%;
}

.footer a {
  margin: 0 10px;
  text-decoration: none;
  color: #3ca84b;
}
</style>
